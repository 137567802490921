import classes from "./InfoCard.module.css";
import React from "react";

const InfoCard = ({ image, title, subTitle, link }) => {
  return (
    <a href={link} style={{ width: "22.6vw" }} target="_blank" rel="noreferrer">
      <div className={classes.Card} style={link ? { cursor: "pointer" } : {}}>
        <div className="text-center">
          <img src={image} alt="" />
        </div>
        <p className={classes.CardTitle}>{title}</p>
        <p className={classes.SubTitle}>{subTitle}</p>
      </div>
    </a>
  );
};

export default InfoCard;
