import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import GraduateImage from '../../assets/grad.png';
import BgWithText from '../../components/BgWithText/BgWithText';
import { DropdownComponent, Input, PhoneInput, TextArea } from '../../components/Inputs/Input';
import { AFFILIATION, TOAST_ERR } from '../../constants';
import { FormSubmitHandler, API } from '../../utils/FormSubmitHandler';
import { SchoolFormInitialValues, SchoolFormValidation } from '../../validations/SchoolFormValidation';
import classes from './Schools.module.css';

const Schools = () => {
  const [isDisabled, setDisabled] = useState(false);
  const recaptchaRef = React.createRef();

  const handleSubmit = async (values, props) => {
    recaptchaRef.current.reset();
    let tempToken = await recaptchaRef.current.executeAsync();
    setDisabled(true);
    if (tempToken) {
      FormSubmitHandler(values, tempToken, API.SCHOOL)
        .then((res) => {
          toast.success(res.data.message);
          props.resetForm(SchoolFormInitialValues);
          setDisabled(false);
        })
        .catch((err) => {
          toast.error(TOAST_ERR);
          setDisabled(false);
        });
    } else {
      toast.error(TOAST_ERR);
      setDisabled(false);
    }
  };

  return (
    <div>
      <BgWithText title='schools' image='bg2' />
      <div className={classes.SchoolContent}>
        <div className={classes.Container}>
          <div style={{ justifyContent: 'space-between', display: 'flex' }}>
            <div style={{ flexDirection: 'column', display: 'flex' }}>
              <div className='LargeText'>
                Alumni <span className='brown'> Interviewing</span>
              </div>
              <p className={classes.SubHeading}>Want to have input in choosing future Harvardians?</p>
              <div className={classes.MobileImage}>
                <img className={classes.GraduateImage} src={GraduateImage} alt='' />
              </div>
              <p>
                Please volunteer for interviewing applicants for Harvard College. The Harvard interview provides the Admissions Office with
                an unbiased assessment to help them go beyond the paper record of the student, and gives the applicant a chance to ask
                questions about Harvard of someone who’s actually been there.
              </p>
              <p>
                Each interview lasts 30 to 45 minutes plus the time you need to write up and submit a one to two page interview report.
                Interviews are conducted in December to March for applicants.
              </p>
            </div>
            <div className={classes.Image}>
              <img className={classes.GraduateImage} src={GraduateImage} alt='' />
            </div>
          </div>
          <p>
            While it is helpful if you have experience interviewing, we’ll provide you with current Harvard information and an annual
            interviewer orientation session. We also need help in attending college fairs (where we pass out literature and answer questions
            from prospective students), organizing receptions for admitted students, and for making occasional presentations to local high
            schools on behalf of Harvard. We currently have over 200 volunteer interviewers in our area and conduct nearly 500 alumni
            interviews each year.
          </p>
          <p style={{ marginTop: "3vw"}}>
            If you are interested, please use your Harvard Key to register <a className='brown' href='https://alumniportal.fas.harvard.edu/documents/index'>here</a>. You will need to complete the eligibility form
            (located under “My Forms”) and select our club CA-10: CA - San Jose, Silicon Valley [Chair:Terry Erisman].  Please
            also complete the form below which helps our club with planning.
          </p>
        </div>
        <div className={classes.FormContainer}>
          <div className={classes.FormContent}>
            <div className='LargeText'>
              <span className='brown'> Schools Committee</span> <br /> Questionnaire
            </div>

            <div className='border'></div>
            <p className='title-text-small brown' style={{ margin: '2em auto' }}>
              Contact Information
            </p>
            {/* FORMSTART */}
            <Formik initialValues={SchoolFormInitialValues} onSubmit={handleSubmit} validationSchema={SchoolFormValidation}>
              {(props) => (
                <Form>
                  <div className={classes.InputContainer}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <Input name='firstName' label='First Name' required></Input>
                      <Input name='lastName' label='Last Name' required></Input>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-around',
                        }}
                        className={classes.Addresses}
                      >
                        <Input
                          name='city'
                          label='City'
                          setFieldValue={props.setFieldValue}
                          setFieldTouched={props.setFieldTouched}
                          getFieldMeta={props.getFieldMeta}
                          required
                        />
                        <Input
                          name='state'
                          label='State'
                          setFieldValue={props.setFieldValue}
                          setFieldTouched={props.setFieldTouched}
                          getFieldMeta={props.getFieldMeta}
                          required
                        />
                      </div>
                      <div>
                        <TextArea label='Address' name='address' required />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <Input
                        name='zipcode'
                        label='Zip Code'
                        setFieldValue={props.setFieldValue}
                        setFieldTouched={props.setFieldTouched}
                        getFieldMeta={props.getFieldMeta}
                        required
                      />
                      <Input name='email' label='Email' required></Input>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <PhoneInput label='Home Phone (Only numeric values)' name='homePhone' required />
                      <PhoneInput label='Work Phone (Only numeric values)' name='workPhone' required />
                    </div>
                  </div>

                  <div className='border' style={{ marginTop: '5.5vw' }}></div>
                  <p className='title-text-small brown' style={{ margin: '2em auto' }}>
                    Additional Information
                  </p>
                  <div className={classes.InputContainer}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <Input name='undergradDegree' label='Undergrad Degree' required />
                      <Input name='concentration' label='Concentration' required />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <Input name='year' label='Year' required></Input>
                      <DropdownComponent
                        name='affiliation'
                        label='affiliation'
                        values={AFFILIATION}
                        setFieldValue={props.setFieldValue}
                        setFieldTouched={props.setFieldTouched}
                        getFieldMeta={props.getFieldMeta}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <Input name='gradDegree' label='Graduate Degree'></Input>
                      <Input name='discipline' label='Discipline'></Input>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <Input name='gradYear' label='Year'></Input>
                      <Input name='jobTitle' label='Job Title'></Input>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <Input name='company' label='Company'></Input>
                      <Input name='hobbies' label='Hobbies and Interests'></Input>
                    </div>
                  </div>

                  <div className='border' style={{ marginTop: '5.5vw' }}></div>
                  <p className='title-text-small brown' style={{ margin: '2em auto' }}>
                    Interview Experience
                  </p>
                  <div className={classes.InputContainer}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <Input name='yrsOfInterviewing' label='Years of Interviewing'></Input>
                      <Input name='noOfInterviews' label='Number of Interviews'></Input>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <ReCAPTCHA ref={recaptchaRef} size='invisible' sitekey='6Lc8buIZAAAAAMIbARG0H5epoGpWoSaDdOPHXIC9' />
                    </div>
                  </div>
                  <button type='submit' disabled={isDisabled} className={classes.Submit + ' btn-primary'}>
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
            {/* FORMEND */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schools;
