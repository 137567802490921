import React from 'react';
import { useHistory } from 'react-router-dom';
import BgWithText from '../../components/BgWithText/BgWithText';
import VolunteerFooter from '../../components/VolunteerFooter/VolunteerFooter';
import VolunteerForm from '../../components/VolunteerForm/VolunteerForm';
import { FUTURE_EVENTS } from '../../constants';
import classes from './Volunteer.module.css';

const Volunteer = () => {
  const history = useHistory();
  return (
    <div className={classes.Volunteer}>
      <BgWithText title='volunteer' image='bg2' />
      <div className={classes.VolunteerContent}>
        <div>
          <div className='LargeText'>
            Join Our Activities <br /> <span className='brown'> Committee</span>
          </div>
          <p>
            Are there any events (or types of events) you’d like to see HCSV host? Join the HCSV Activity Committee! Come have dinner, meet
            old and new friends, and share your ideas. Some suggestions for future events include:
          </p>
          <div className={classes.EventsContainer}>
            {FUTURE_EVENTS.map((event) => (
              <div className={classes.EventButton + ' btn-secondary'}>{event}</div>
            ))}
          </div>
          <p>
            Share your event ideas with us! Harvard Club of Silicon Valley is a volunteer-run organization, and all events are organized by
            our members.
          </p>
          <p>
            To get involved with the Activity Committee or send a suggestion, email us at{' '}
            <a
              target='_blank'
              rel='noreferrer'
              href='mailto:activities@harvardclubsv.org'
              style={{ cursor: 'pointer' }}
              className='brown underline'
            >
              {' '}
              activities@harvardclubsv.org.
            </a>{' '}
            Alumni from all Harvard University schools are welcome.
          </p>
        </div>
        <VolunteerForm />
        <div className={classes.Container}>
          <div className='LargeText'>
            <span className='brown'> Schools Committee</span>
            <br /> Alumni Interviewers
          </div>
          <p className={classes.SubHeading}>Want to have input in choosing future Harvardians?</p>
          <p>
            Please volunteer for interviewing applicants for Harvard College. The Harvard interview provides the Admissions Office with an
            unbiased assessment to help them go beyond the paper record of the student, and gives the applicant a chance to ask questions
            about Harvard of someone who’s actually been there.
          </p>
          <p>
            Each interview lasts 30 to 45 minutes plus the time you need to write up and submit a one to two page interview report.
            Interviews are conducted in December to March for applicants. While it is helpful if you have experience interviewing, we’ll
            provide you with current Harvard information and an annual interviewer orientation session.
          </p>
          <p className={classes.DoubleSpace}>
            We also need help in attending college fairs (where we pass out literature and answer questions from prospective students),
            organizing receptions for admitted students, and for making occasional presentations to local high schools on behalf of Harvard.
            We currently have over 200 volunteer interviewers in our area and conduct nearly 500 alumni interviews each year.
            If you are
            interested, please review the details and register
            <span className='brown' onClick={() => history.push('/schools')} style={{ cursor: 'pointer' }}>
              {' '}here
            </span>{' '}
            or contact our
            <a className='brown' target='_blank' rel='noreferrer' href='mailto:terisman@post.harvard.edu' style={{ cursor: 'pointer' }}>
              {' '}
              Schools Committee Chair
            </a>{' '}
          </p>
        </div>
        {/* <div className={classes.Container}>
          <div className='LargeText'>
            <span className='brown'>Book </span>
            Awards
          </div>
          <p>
            Harvard Book Awards are awarded to outstanding students in their junior year of high school. The winners are selected by each
            participating high school based on academic excellence, exceptional personal qualities and significant contribution to their
            school or community. At the annual awards ceremony of the participating schools, each winner is presented with a book that is
            inscribed to the recipient.
          </p>
          <p>
            The objective of the Harvard Book Award program is to raise visibility for Harvard College among secondary schools and encourage
            qualified students to apply for admission. Since 1910, the Harvard Book Award has been an important way for Harvard Club members
            worldwide to help attract talented young people to Harvard College and the opportunities in Cambridge.
          </p>
          <p>
            Harvard Book Prizes are presented annually in more than 1,900 high schools around the world and are made available through
            generous donations from individual Harvard Club members.Each Harvard Book Award costs $30. Your generous donation will allow the
            Harvard Club of Silicon Valley to expand the Harvard Prize Book program to more of the seventy plus high schools in Santa Clara
            County. Please donate now!
          </p>
        </div> */}
        <div className={classes.Footer}>
          <VolunteerFooter />
        </div>
      </div>
    </div>
  );
};

export default Volunteer;
